import React, { useEffect } from 'react';
import {
  Box,
  Container,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Form from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationAction } from '../../actions/applicationAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TopicView = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { application, applicationLoading, applicationForm } = useSelector(state => state.application);

  useEffect(() => {
    dispatch(getApplicationAction());
  }, [])

  return (
    <Page
      className={classes.root}
      title="Uygulamalar"
    >
      {
        applicationLoading &&
        <CircularProgress />
      }
      <Form />
      <Container maxWidth={false}>
        <Toolbar
        />
        <Box mt={3}>
          <Results />
        </Box>
      </Container>
    </Page>
  );
};

export default TopicView;
