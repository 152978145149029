import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Select,
  Typography,
  InputLabel,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import { useEffect } from 'react';
import { getUserApplication, changeApplication } from '../../services/ApiService'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ChangeAppView = () => {
  const classes = useStyles();
  const [application, setApplication] = useState([]);
  const [selectApp, setSelectApp] = useState(1);

  useEffect(() => {
    getUserApplication().then(res => {
      setApplication(res.data);
    });
  }, [])

  const changeOnPress = () => {
    changeApplication(selectApp).then(res => {
      location.href = '/';
    });
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Uygulama Değiştir
            </Typography>
          </Box>
          <Box
            mt={3}
            mb={1}
          >
          </Box>
          <InputLabel style={{ marginTop: 20 }} id="demo-simple-select-helper-label">Uygulamalar</InputLabel>
          <Select
            value={selectApp}
            fullWidth
            onChange={(val) => { setSelectApp(val.target.value) }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={''}>
              <em>Seçiniz</em>
            </MenuItem>
            {
              application?.map((res, index) => {
                return (
                  <MenuItem value={res.id}>{res.name}</MenuItem>
                )
              })
            }
          </Select>
          <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              onClick={changeOnPress}
              variant="contained"
            >
              Giriş Yap
            </Button>
          </Box>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Otelz @2021
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default ChangeAppView;
