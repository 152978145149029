import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Tag as TagIcon,
  Package as PackageIcon,
  Server as ServerIcon,
  Bell as BellIcon,
  Grid as GridIcon
} from 'react-feather';
import NavItem from './NavItem';
import { getUserInfo } from '../../../services/AuthService';

const items = [
  {
    href: '/',
    path: 'dashboard',
    icon: BarChartIcon,
    title: 'Panel'
  },
  {
    href: '/notification',
    path: 'notification',
    icon: BellIcon,
    title: 'Bildirimler'
  },
  {
    href: '/template',
    path: 'template',
    icon: GridIcon,
    title: 'Şablonlar'
  },
  {
    href: '/topic',
    path: 'topic',
    icon: TagIcon,
    title: 'Etiketler'
  },
  {
    href: '/applications',
    path: 'applications',
    icon: PackageIcon,
    title: 'Uygulamalar'
  },
  //{
  //  href: '/servers',
  //  path: 'servers',
  //  icon: ServerIcon,
  //  title: 'Sunucular'
  //},
  //{
  //  href: '/applicationserver',
  //  path: 'applicationserver',
  //  icon: UsersIcon,
  //  title: 'Uygulama Sunucuları'
  //}
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));


const PageList = () => {
  return (
    items.map((item) => (
      <NavItem
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
    ))
  )
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = getUserInfo();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={'/user.png'}
          to="#"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
        {user.fullName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.applicationName}
        </Typography>
      </Box>
    
      <Box p={2}>
        <List>
          <PageList />
        </List>
      </Box>
    </Box>
  );


  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
