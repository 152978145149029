import 'react-perfect-scrollbar/dist/css/styles.css';
// import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import { store } from './services/Store'
import { Provider } from 'react-redux';
import Routes from './Route'
//import NotificationService from './services/NotificationService'

const App = () => {

  // useEffect(() => {
  //   NotificationService.registerToken();
  // }, []);

  // navigator.serviceWorker.addEventListener("message", ({ data }) => {
  //   store().dispatch({
  //     type: 'NOTIFICATON_MESSAGE',
  //     payload: data
  //   })
  //   const { notification } = data;
  //   const notificationData = notification?.data;
  //   const { title, body } = notification;
  //   alert('Yeni Bildirim Geldi.! Mesaj : ' + title + ' ' + body);
  // });

  return (
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
};

export default App;