import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { getTopicAction, insertOrUpdateNotificationAction, notificationFormAction } from '../../actions'
import { DeviceTypeList } from '../../models/deviceType'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function FormDialog() {

    const defaultState = {
        title: '',
        body: '',
        deviceType: null,
        data: null,
        deviceId: null,
        externalUserId: null,
        imageUrl: '',
        sound: '',
        applicationTopicId: null,
        pushDate: null
    }
    const classes = useStyles();
    const [values, setValues] = useState(defaultState);
    const { topicResponse } = useSelector(state => state.topic);
    const { notification, notificationLoading, notificationForm } = useSelector(state => state.notification);
    const { modalShow, clearForm } = notificationForm;
    const text = values?.id ? 'Güncelle' : 'Oluştur';
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTopicAction());
    }, [])

    const handleClose = () => {
        dispatch(notificationFormAction({ modalShow: false }));
        setValues(defaultState);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const addOrUpdate = (event) => {
        dispatch(insertOrUpdateNotificationAction(values));
    }
    return (
        <Dialog fullWidth open={modalShow && !notificationLoading} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bildirim {text}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    label="Başlık"
                    type="text"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                    label="Mesaj"
                    type="text"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="externalUserId"
                    value={values.externalUserId}
                    onChange={handleChange}
                    label="Kullanıcı Id"
                    type="text"
                    fullWidth
                />
                <TextField
                    margin="dense"
                    name="data"
                    value={values.data}
                    onChange={handleChange}
                    label="Veri"
                    type="text"
                    fullWidth
                />

                <TextField
                    margin="dense"
                    name="imageUrl"
                    value={values.imageUrl}
                    onChange={handleChange}
                    label="Web Resim Adresi"
                    type="text"
                    fullWidth
                />

                <TextField
                    margin="dense"
                    name="sound"
                    value={values.sound}
                    placeholder={'default'}
                    onChange={handleChange}
                    label="Bildirim Sesi"
                    type="text"
                    fullWidth
                />

                <InputLabel style={{ marginTop: 20 }} id="demo-simple-select-helper-label">Etiket</InputLabel>
                <Select
                    value={values.applicationTopicId}
                    fullWidth
                    name={'applicationTopicId'}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                        <em>Seçiniz</em>
                    </MenuItem>
                    {
                        topicResponse.map((res, index) => {
                            return (
                                <MenuItem key={index} value={res.id}>{res.name}</MenuItem>
                            )
                        })
                    }
                </Select>

                <InputLabel style={{ marginTop: 20 }} id="demo-simple-select-helper-label">Platform</InputLabel>
                <Select
                    value={values.deviceType}
                    fullWidth
                    name={'deviceType'}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                        <em>Tümü</em>
                    </MenuItem>
                    {
                        DeviceTypeList.map((res, index) => {
                            return (
                                <MenuItem key={index} value={res.value}>{res.key}</MenuItem>
                            )
                        })
                    }
                </Select>

                <form className={classes.container} noValidate>
                    <TextField
                        id="datetime-local"
                        label="Gönderme Zamanı"
                        type="datetime-local"
                        onChange={handleChange}
                        name={'pushDate'}
                        defaultValue={values.pushDate}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    İptal
                </Button>
                <Button onClick={addOrUpdate} color="primary">
                    {text}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
