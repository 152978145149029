import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { getTemplateAction, templateDetailModalAction, insertOrUpdateNotificationAction } from '../../actions'
import { DeviceTypeList } from '../../models/deviceType'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
}));

export default function DetailDialog() {

    const defaultState = {
        templateName: '',
        title: '',
        body: '',
        deviceType: null,
        data: null,
        imageUrl: '',
        sound: '',
        applicationTopicId: null
    }
    const classes = useStyles();
    const [values, setValues] = useState(defaultState);
    const { template, templateLoading, templateDetailModal } = useSelector(state => state.template)
    const { modalShow, clearForm } = templateDetailModal;
    const text = values?.templateName ? values.templateName : '';
    const dispatch = useDispatch()

    useEffect(() => {
        if (template?.id) {
            setValues(template);
        }
    }, [template]);

    useEffect(() => {
        dispatch(getTemplateAction());
    }, []);

    const handleClose = () => {
        dispatch(templateDetailModalAction({ modalShow: false }));
        setValues(defaultState);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const sendNotification = (event) => {
        dispatch(insertOrUpdateNotificationAction(values))
        dispatch(templateDetailModalAction({ modalShow: false }))
    }

    return (
        <Dialog fullWidth open={modalShow && !templateLoading} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{text} - Detay</DialogTitle>
            <DialogContent>

                <Form
                    title={'Şablon Adı'}
                    detail={values.templateName}
                />

                <Form
                    title={'Başlık'}
                    detail={values.title}
                />

                <Form
                    title={'Mesaj'}
                    detail={values.body}
                />

                <Form
                    title={'Web Resim Adresi'}
                    detail={values.imageUrl}
                />

                <Form
                    title={'Bildirim Sesi'}
                    detail={values.sound}
                />

                <Form
                    title={'Veri'}
                    detail={values.data}
                />

                <InputLabel style={{ marginTop: 20 }} id="demo-simple-select-helper-label">Platform</InputLabel>
                <Select
                    value={values.deviceType}
                    fullWidth
                    name={'deviceType'}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                        <em>Tümü</em>
                    </MenuItem>
                    {
                        DeviceTypeList.map((res, index) => {
                            return (
                                <MenuItem key={index} value={res.value}>{res.key}</MenuItem>
                            )
                        })
                    }
                </Select>

                {/* <form className={classes.container} noValidate> */}
                {/* <TextField
                            id="datetime-local"
                            label="Gönderme Zamanı"
                            type="datetime-local"
                            onChange={handleChange}
                            name={'pushDate'}
                            defaultValue={values.pushDate}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true
                            }}
                        /> */}
                {/* </form> */}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    İptal
                </Button>
                <Button onClick={sendNotification} color="primary">
                    Gönder
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const Form = ({ title, detail }) => {
    return (
        <Grid style={{ marginBottom: 15 }}>
            <FormLabel
                style={{ fontWeight: 'bold' }}>
                {title} :
            </FormLabel>
            <FormLabel style={{ marginLeft: 5 }}>
                {detail}
            </FormLabel>
        </Grid>
    )
}