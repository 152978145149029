import {
    APPLICATIONSERVER_HTTP_ERROR, APPLICATIONSERVER_POST_ERROR, APPLICATIONSERVER_POST_LOADING,
    APPLICATIONSERVER_POST, APPLICATIONSERVER_HTTP_RESPONSE, APPLICATIONSERVER_HTTP_LOADING, APPLICATIONSERVER_POST_FORM, APPLICATIONSERVER_APPLICATION_ID
} from '../constants/types';

const INITIAL_STATE = {
    applicationServerResponse: [],
    applicationServerResponseLoading: false,
    applicationServerResponseError: null,
    applicationServer: {},
    applicationServerLoading: false,
    applicationServerForm: {},
    applicationId: null
}

const applicationServerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case APPLICATIONSERVER_HTTP_RESPONSE:
            return { ...state, applicationServerResponseLoading: false, applicationServerResponseError: false, applicationServerResponse: action.payload }
        case APPLICATIONSERVER_HTTP_LOADING:
            return { ...state, applicationServerResponseLoading: action.payload }
        case APPLICATIONSERVER_HTTP_ERROR:
            return { ...state, applicationServerLoading : false, applicationServerResponseError: action.payload }
        case APPLICATIONSERVER_POST:
            return { ...state, applicationServer: action.payload, applicationServerLoading: false }
        case APPLICATIONSERVER_POST_LOADING:
            return { ...state, applicationServerLoading: action.payload }
        case APPLICATIONSERVER_POST_FORM:
            return { ...state, applicationServerForm: action.payload }
        case APPLICATIONSERVER_APPLICATION_ID:
            return { ...state, applicationId: action.payload }
        default:
            return state;
    }
}

export { applicationServerReducer }