import {
    TEMPLATE_HTTP_ERROR, TEMPLATE_POST_LOADING,
    TEMPLATE_POST, TEMPLATE_HTTP_RESPONSE, TEMPLATE_DETAIL_FORM, TEMPLATE_HTTP_LOADING, TEMPLATE_POST_FORM
} from '../constants/types';

const INITIAL_STATE = {
    templateResponse: [],
    templateResponseLoading: false,
    templateResponseError: null,
    template: {},
    templateLoading: false,
    templateForm: {},
    templateDetailModal: {}
}

const templateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TEMPLATE_HTTP_RESPONSE:
            return { ...state, templateResponseLoading: false, templateResponseError: false, templateResponse: action.payload }
        case TEMPLATE_HTTP_LOADING:
            return { ...state, templateResponseLoading: action.payload }
        case TEMPLATE_HTTP_ERROR:
            return { ...state, templateLoading : false, templateResponseError: action.payload }
        case TEMPLATE_POST:
            return { ...state, template: action.payload, templateLoading: false }
        case TEMPLATE_POST_LOADING:
            return { ...state, templateLoading: action.payload }
        case TEMPLATE_POST_FORM:
            return { ...state, templateForm: action.payload }
        case TEMPLATE_DETAIL_FORM:
            return { ...state, templateDetailModal: action.payload }
        default:
            return state;
    }
}

export { templateReducer }