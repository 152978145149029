import axios from 'axios';
import { getToken, getUserInfo } from '../services/AuthService'

const defaultHeader = {
    'Content-Type': 'application/json'
}

const responseType = 'json';
const apiUrl = '/api';

class HttpService {

    constructor() {
        let service = axios.create({
            baseURL: `${apiUrl}`
        });

        service.interceptors.request.use(
            async config => {
                const token = getToken();
                const { apiKey } = getUserInfo();
                if (token) {
                    config.headers = { 'Authorization': `bearer ${token}`, 'x-oz-notify-api-key': apiKey }
                }
                return config
            }
        );

        this.service = service;
        service.interceptors.response.use(this.handleSuccess, this.handleError);
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.log('401')
                    new AuthService().login();
                    break;
                case 404:
                    console.log('404')
                    break;
                default:
                    console.log(error)
                    break;
            }
        }
        return Promise.reject(error)
    }

    get(path, params) {
        return this.service.request({
            url: path,
            params: params
        });
    }

    post(path, payload) {
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: responseType,
            data: payload,
            headers: defaultHeader
        });
    }

    put(path, payload) {
        return this.service.request({
            method: 'PUT',
            url: path,
            responseType: responseType,
            data: payload,
            headers: defaultHeader
        });
    }
}

export default new HttpService;