

const tokenKey = 'token';
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie';

export const signOut = () => {
    const cookies = new Cookies();
    cookies.remove(tokenKey);
    location.reload();
}

export const isAuth = () => {
    return getToken() ? true : false;
}

export const getToken = () => {
    const cookies = new Cookies();
    return cookies.get(tokenKey);
}

export const getUserInfo = () => {
    if (isAuth()) {
        const userInfo = jwtDecode(getToken());
        return {
            applicationId: userInfo?.applicationId,
            applicationName: userInfo?.applicationName,
            apiKey: userInfo?.apiKey,
            userName: userInfo?.userName,
            userId: userInfo?.userId,
            fullName : userInfo?.fullName
        }
    }
    return {
        applicationId: 0,
        applicationName: undefined,
        apiKey: undefined,
        userName: undefined,
        userId: undefined,
        fullName : undefined
    }
}

