export const TOPIC_HTTP_LOADING = 'TOPIC_HTTP_LOADING';
export const TOPIC_HTTP_RESPONSE = 'TOPIC_HTTP_RESPONSE';
export const TOPIC_HTTP_ERROR = 'TOPIC_HTTP_ERROR';

export const TOPIC_POST = 'TOPIC_POST';
export const TOPIC_POST_FORM = 'TOPIC_POST_FORM';
export const TOPIC_POST_LOADING = 'TOPIC_POST_LOADING';
export const TOPIC_POST_ERROR = 'TOPIC_POST_ERROR';


//Notification
export const NOTIFICATION_HTTP_LOADING = 'NOTIFICATION_HTTP_LOADING';
export const NOTIFICATION_HTTP_RESPONSE = 'NOTIFICATION_HTTP_RESPONSE';
export const NOTIFICATION_HTTP_ERROR = 'NOTIFICATION_HTTP_ERROR';

export const NOTIFICATION_POST = 'NOTIFICATION_POST';
export const NOTIFICATION_POST_FORM = 'NOTIFICATION_POST_FORM';
export const NOTIFICATION_POST_LOADING = 'NOTIFICATION_POST_LOADING';
export const NOTIFICATION_POST_ERROR = 'NOTIFICATION_POST_ERROR';


//Template
export const TEMPLATE_HTTP_LOADING = 'TEMPLATE_HTTP_LOADING';
export const TEMPLATE_HTTP_RESPONSE = 'TEMPLATE_HTTP_RESPONSE';
export const TEMPLATE_HTTP_ERROR = 'TEMPLATE_HTTP_ERROR';

export const TEMPLATE_POST = 'TEMPLATE_POST';
export const TEMPLATE_POST_FORM = 'TEMPLATE_POST_FORM';
export const TEMPLATE_DETAIL_FORM = 'TEMPLATE_DETAIL_FORM';
export const TEMPLATE_POST_LOADING = 'TEMPLATE_POST_LOADING';
export const TEMPLATE_POST_ERROR = 'TEMPLATE_POST_ERROR';

// Server
export const SERVER_HTTP_LOADING = 'SERVER_HTTP_LOADING';
export const SERVER_HTTP_RESPONSE = 'SERVER_HTTP_RESPONSE';
export const SERVER_HTTP_ERROR = 'SERVER_HTTP_ERROR';

export const SERVER_POST = 'SERVER_POST';
export const SERVER_POST_FORM = 'SERVER_POST_FORM';
export const SERVER_POST_LOADING = 'SERVER_POST_LOADING';
export const SERVER_POST_ERROR = 'SERVER_POST_ERROR';

// Application
export const APPLICATION_HTTP_LOADING = 'APPLICATION_HTTP_LOADING';
export const APPLICATION_HTTP_RESPONSE = 'APPLICATION_HTTP_RESPONSE';
export const APPLICATION_HTTP_ERROR = 'APPLICATION_HTTP_ERROR';

export const APPLICATION_POST = 'APPLICATION_POST';
export const APPLICATION_POST_FORM = 'APPLICATION_POST_FORM';
export const APPLICATION_POST_LOADING = 'APPLICATION_POST_LOADING';
export const APPLICATION_POST_ERROR = 'APPLICATION_POST_ERROR';

// Application Server
export const APPLICATIONSERVER_HTTP_LOADING = 'APPLICATIONSERVER_HTTP_LOADING';
export const APPLICATIONSERVER_HTTP_RESPONSE = 'APPLICATIONSERVER_HTTP_RESPONSE';
export const APPLICATIONSERVER_HTTP_ERROR = 'APPLICATIONSERVER_HTTP_ERROR';

export const APPLICATIONSERVER_POST = 'APPLICATIONSERVER_POST';
export const APPLICATIONSERVER_POST_FORM = 'APPLICATIONSERVER_POST_FORM';
export const APPLICATIONSERVER_POST_LOADING = 'APPLICATIONSERVER_POST_LOADING';
export const APPLICATIONSERVER_POST_ERROR = 'APPLICATIONSERVER_POST_ERROR';

export const APPLICATIONSERVER_APPLICATION_ID = 'APPLICATIONSERVER_APPLICATION_ID';

// account action
export const ACCOUNT_HTTP_LOADING = 'ACCOUNT_HTTP_LOADING';
export const ACCOUNT_HTTP_RESPONSE = 'ACCOUNT_HTTP_RESPONSE';
export const ACCOUNT_HTTP_ERROR = 'ACCOUNT_HTTP_ERROR';


export const dispatcher = (dispatch, type, payload) => {
    dispatch({
        type: type,
        payload: payload
    });
};
