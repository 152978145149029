import React from 'react';

const Logo = (props) => {
  return (
    <>
      <img
        alt="Logo"
        style={{ height: 30, width: 160 }}
        src="/static/images/logo.svg"
        {...props}
      />
    </>
  );
};

export default Logo;
