import {
    TEMPLATE_HTTP_LOADING, TEMPLATE_HTTP_RESPONSE,
    TEMPLATE_POST, TEMPLATE_POST_ERROR, TEMPLATE_POST_FORM, TEMPLATE_POST_LOADING,
    TEMPLATE_HTTP_ERROR, dispatcher, TEMPLATE_DETAIL_FORM
} from "../constants/types";
import {
    getNotificationTemplate, getNotificationTemplateById,
    insertNotificationTemplate, updateNotificationTemplate
} from '../services/ApiService';

const formType = { modalShow: false, clearForm: false }

export const getTemplateAction = (id) => {
    return (dispatch) => {
        if (id) {
            dispatcher(dispatch, TEMPLATE_POST_LOADING, true);
            getNotificationTemplateById(id).then(res => {
                dispatcher(dispatch, TEMPLATE_POST, res.data);
            }).catch(err => { dispatcher(dispatch, TEMPLATE_HTTP_ERROR, err) });
        }
        else {
            dispatcher(dispatch, TEMPLATE_HTTP_LOADING, true);
            getNotificationTemplate().then(res => {
                dispatcher(dispatch, TEMPLATE_HTTP_RESPONSE, res.data.data);
            }).catch(err => { dispatcher(dispatch, TEMPLATE_HTTP_ERROR, err) });
        }
    };
};

export const templateFormAction = (form = formType) => {
    return (dispatch) => {
        console.log(form)
        dispatcher(dispatch, TEMPLATE_POST_FORM, form);
    };
};

export const templateDetailModalAction = (form = formType) => {
    return (dispatch) => {
        console.log(form)
        dispatcher(dispatch, TEMPLATE_DETAIL_FORM, form);
    };
};

export const insertOrUpdateTemplateAction = (input) => {
    return (dispatch) => {
        dispatcher(dispatch, TEMPLATE_POST_LOADING, true);
        if (input.id) {
            updateNotificationTemplate(input.id, input).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, TEMPLATE_POST_ERROR, err) })
        }
        else {
            insertNotificationTemplate(input).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, TEMPLATE_POST_ERROR, err) })
        }
    };
};

const complatePost = (dispatch) => {
    dispatcher(dispatch, TEMPLATE_POST_LOADING, false);
    dispatcher(dispatch, TEMPLATE_HTTP_LOADING, false);
    dispatcher(dispatch, TEMPLATE_POST_FORM, { modalShow: false, clearForm: true });
    getNotificationTemplate().then(res => {
        dispatcher(dispatch, TEMPLATE_HTTP_RESPONSE, res.data.data);
    }).catch(err => { dispatcher(dispatch, TEMPLATE_HTTP_ERROR, err) });
}

