import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  makeStyles,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { getApplicationAction, applicationFormAction } from '../../actions'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { applicationResponse, applicationResponseLoading } = useSelector(state => state.application)
  const dispatch = useDispatch()

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onPressEdit = (id) => {
    dispatch(getApplicationAction(id));
    dispatch(applicationFormAction({ modalShow: true }));
  }

  return (
    <>
      {
        applicationResponseLoading && <CircularProgress/>
      }
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Uygulama Adı
                  </TableCell>
                  <TableCell>
                    API Anahtarı
                  </TableCell>
                  <TableCell>
                    Uygulama Adresleri
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {applicationResponse?.slice(0, limit).map((application) => (
                  <TableRow
                    hover
                    key={application.id}
                  // selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                  >
                    <TableCell>
                      {application.name}
                    </TableCell>
                    <TableCell>
                      {application.apiKey}
                    </TableCell>
                    <TableCell>
                      {application.url}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        onClick={() => { onPressEdit(application.id) }}
                        variant="contained"
                      >
                        Düzenle
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={applicationResponse.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
