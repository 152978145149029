import {
    SERVER_HTTP_ERROR, SERVER_POST_ERROR, SERVER_POST_LOADING,
    SERVER_POST, SERVER_HTTP_RESPONSE, SERVER_HTTP_LOADING, SERVER_POST_FORM
} from '../constants/types';

const INITIAL_STATE = {
    serverResponse: [],
    serverResponseLoading: false,
    serverResponseError: null,
    server: {},
    serverLoading: false,
    serverForm : {}
}

const serverReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SERVER_HTTP_RESPONSE:
            return { ...state, serverResponseLoading: false, serverResponseError: false, serverResponse: action.payload }
        case SERVER_HTTP_LOADING:
            return { ...state, serverResponseLoading: action.payload }
        case SERVER_HTTP_ERROR:
            return { ...state, serverResponseError: action.payload }
        case SERVER_POST:
            return { ...state, server: action.payload, serverLoading: false }
        case SERVER_POST_LOADING:
            return { ...state, serverLoading: action.payload }
        case SERVER_POST_FORM:
            return { ...state, serverForm: action.payload }
        default:
            return state;
    }
}

export { serverReducer }