import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { insertOrUpdateTopicAction, topicFormAction } from '../../actions'

export default function FormDialog() {

    const defaultState = { id: 0, name: '', applicationId: 0, autoSubscribe: false };
    const [values, setValues] = useState(defaultState);
    const { topic, topicLoading, topicForm } = useSelector(state => state.topic);
    const { modalShow, clearForm } = topicForm;
    const text = values?.id ? 'Güncelle' : 'Oluştur';
    const dispatch = useDispatch();

    useEffect(() => {
        if (topic?.id) {
            setValues(topic);
        }
    }, [topic]);

    const handleClose = () => {
        dispatch(topicFormAction({ modalShow: false }));
        setValues(defaultState);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeCheck = (event) => {
        setValues({
            ...values,
            autoSubscribe: event.target.checked
        });
    };

    const addOrUpdate = (event) => {
        if (values.name === undefined || values.name === '') {
            alert('Lütfen etiket adı giriniz')
        }
        else {
            dispatch(insertOrUpdateTopicAction(values))
        }
    };

    return (
        <>
            <Dialog fullWidth open={modalShow && !topicLoading} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Etiket {text}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Etiket adı"
                        type="text"
                        fullWidth
                    />
                    <InputLabel style={{ marginTop: 20 }} id="demo-simple-select-helper-label">Otomatik Dahil Olma</InputLabel>
                    <Checkbox
                        checked={values.autoSubscribe}
                        onChange={handleChangeCheck}
                        name={'autoSubscribe'}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        İptal
                    </Button>
                    <Button onClick={addOrUpdate} color="primary">
                        {text}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}