import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import Budget from './Components/Budget';
import TrafficByDevice from './Components/TrafficByDevice';
import { getApplicationStatistics } from '../../../services/ApiService';
import QRCode from './Components/QrCode'
import HealthCheckDevices from './Components/HealthCheckDevices'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const [statics, setStatics] = useState();

  useEffect(() => {
    getApplicationStatistics().then(res => {
      setStatics(res.data);
    });
  }, [])

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget
              title={'Etiketler'}
              value={statics?.totalApplicationTopic} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget
              title={'Cihazlar'}
              value={statics?.totalDevice} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget
              title={'Bildirimler'}
              value={statics?.notificationCount} />
          </Grid>

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          />

          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice
              ios={statics?.totalDeviceIos}
              web={statics?.totalDeviceWeb}
              android={statics?.totalDeviceAndroid} />
          </Grid>

          {/*<Grid*/}
          {/*  item*/}
          {/*  lg={4}*/}
          {/*  md={6}*/}
          {/*  xl={3}*/}
          {/*  xs={12}*/}
          {/*>*/}
          {/*  <HealthCheckDevices />*/}
          {/*</Grid>*/}

          {/*<Grid*/}
          {/*  item*/}
          {/*  lg={4}*/}
          {/*  md={6}*/}
          {/*  xl={3}*/}
          {/*  xs={12}*/}
          {/*>*/}
          {/*  <QRCode />*/}
          {/*</Grid>*/}

        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
