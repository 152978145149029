import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  makeStyles,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { notificationResponse, notificationResponseLoading } = useSelector(state => state.notification)
  const dispatch = useDispatch()

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function getStatusName(statusId) {
    if (statusId == 1) {
      return "Bekliyor"
    }
    if (statusId == 2) {
      return "Gönderiliyor"
    }
    if (statusId == 3) {
      return "Hata Oluştu"
    }
    if (statusId == 4) {
      return "Başarılı"
    }
  }

  return (
    <>
      {
        notificationResponseLoading && <CircularProgress />
      }
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Başlık
                  </TableCell>
                  <TableCell>
                    Mesaj
                  </TableCell>
                  <TableCell>
                    Zamanlanmış Gönderme Zamanı
                  </TableCell>
                  <TableCell>
                    Durum
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationResponse?.slice(0, limit).map((notification) => (
                  <TableRow
                    hover
                    key={notification.id}
                  // selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                  >
                    <TableCell>
                      {notification.title}
                    </TableCell>
                    <TableCell>
                      {notification.body}
                    </TableCell>
                    <TableCell>
                      {notification.pushDate ? notification.pushDate : 'Hemen Gönder'}
                    </TableCell>
                    <TableCell>
                      {getStatusName(notification.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={notificationResponse?.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
