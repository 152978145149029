import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { getHealthCheckDevice } from '../../../../services/ApiService'

import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

const useStyles = makeStyles(({
  root: {
    height: '100%'
  },
  image: {
    height: 70,
    width: 70
  }
}));

const HealthCheckDevices = ({ className, ...rest }) => {
  const classes = useStyles();
  const [products, setProducts] = useState();

  useEffect(() => {
    getHealthCheckDevice().then(res => {
      setProducts(res.data);
    });

  }, [])

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        subtitle={`${products?.length} in total`}
        title="Sağlık Kontrolü Cihazlar"
      />
      <Divider />
      <List>
        {products?.map((product, i) => (
          <ListItem
            divider={i < products?.length - 1}
            key={product.deviceUniqeId}
          >
            <ListItemAvatar>
              {
                product.deviceType == 1 ?
                  <AppleIcon style={{ width: 30, height: 30 }} />
                  : <AndroidIcon style={{ width: 30, height: 30 }} />
              }
            </ListItemAvatar>
            <ListItemText
              primary={product.userFullName}
              secondary={product.deviceModel}
            />
            <IconButton
              edge="end"
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          disabled
          variant="text"
        >
          Tümünü Gör
        </Button>
      </Box>
    </Card>
  );
};

HealthCheckDevices.propTypes = {
  className: PropTypes.string
};

export default HealthCheckDevices;
