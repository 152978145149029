import {
    NOTIFICATION_HTTP_LOADING, NOTIFICATION_HTTP_RESPONSE,
    NOTIFICATION_POST, NOTIFICATION_POST_ERROR, NOTIFICATION_POST_FORM, NOTIFICATION_POST_LOADING,
    NOTIFICATION_HTTP_ERROR, dispatcher
} from "../constants/types";
import {
    getNotification, sendNotification
} from '../services/ApiService';

const formType = { modalShow: false, clearForm: false }

export const getNotificationAction = () => {
    return (dispatch) => {
        dispatcher(dispatch, NOTIFICATION_HTTP_LOADING, true);
        getNotification().then(res => {
            dispatcher(dispatch, NOTIFICATION_HTTP_RESPONSE, res.data.data);
        }).catch(err => { dispatcher(dispatch, NOTIFICATION_HTTP_ERROR, err) });
    };
};

export const notificationFormAction = (form = formType) => {
    return (dispatch) => {
        console.log(form)
        dispatcher(dispatch, NOTIFICATION_POST_FORM, form);
    };
};

export const insertOrUpdateNotificationAction = (notificationInput) => {
    return (dispatch) => {
        console.log(notificationInput);
        dispatcher(dispatch, NOTIFICATION_POST_LOADING, true);
        sendNotification(notificationInput).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, NOTIFICATION_POST_ERROR, err) })
    };
};

const complatePost = (dispatch) => {
    dispatcher(dispatch, NOTIFICATION_POST_LOADING, false);
    dispatcher(dispatch, NOTIFICATION_HTTP_LOADING, false);
    dispatcher(dispatch, NOTIFICATION_POST_FORM, { modalShow: false, clearForm: true });
    getNotification().then(res => {
        dispatcher(dispatch, NOTIFICATION_HTTP_RESPONSE, res.data.data);
    }).catch(err => { dispatcher(dispatch, NOTIFICATION_HTTP_ERROR, err) });
}

