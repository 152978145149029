import {
    TOPIC_HTTP_LOADING, TOPIC_HTTP_RESPONSE,
    TOPIC_POST, TOPIC_POST_ERROR, TOPIC_POST_FORM, TOPIC_POST_LOADING,
    TOPIC_HTTP_ERROR, dispatcher
} from "../constants/types";
import {
    getApplicationTopic, getApplicationTopicById,
    insertApplicationTopic, updateApplicationTopic
} from '../services/ApiService';

const formType = { modalShow: false, clearForm: false }

export const getTopicAction = (id) => {
    return (dispatch) => {
        if (id) {
            dispatcher(dispatch, TOPIC_POST_LOADING, true);
            getApplicationTopicById(id).then(res => {
                dispatcher(dispatch, TOPIC_POST, res.data);
            }).catch(err => { dispatcher(dispatch, TOPIC_HTTP_ERROR, err) });
        }
        else {
            dispatcher(dispatch, TOPIC_HTTP_LOADING, true);
            getApplicationTopic().then(res => {
                dispatcher(dispatch, TOPIC_HTTP_RESPONSE, res.data);
            }).catch(err => { dispatcher(dispatch, TOPIC_HTTP_ERROR, err) });
        }
    };
};

export const topicFormAction = (form = formType) => {
    return (dispatch) => {
        console.log(form)
        dispatcher(dispatch, TOPIC_POST_FORM, form);
    };
};

export const insertOrUpdateTopicAction = (topicInput) => {
    return (dispatch) => {
        dispatcher(dispatch, TOPIC_POST_LOADING, true);
        if (topicInput.id) {
            updateApplicationTopic(topicInput).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, TOPIC_POST_ERROR, err) })
        }
        else {
            insertApplicationTopic(topicInput).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, TOPIC_POST_ERROR, err) })
        }
    };
};

const complatePost = (dispatch) => {
    dispatcher(dispatch, TOPIC_POST_LOADING, false);
    dispatcher(dispatch, TOPIC_HTTP_LOADING, false);
    dispatcher(dispatch, TOPIC_POST_FORM, { modalShow: false, clearForm: true });
    getApplicationTopic().then(res => {
        dispatcher(dispatch, TOPIC_HTTP_RESPONSE, res.data);
    }).catch(err => { dispatcher(dispatch, TOPIC_HTTP_ERROR, err) });
}

