import { combineReducers } from 'redux';
import { topicReducer } from './topicReducer';
import { notificationReducer } from './notificationReducer';
import { serverReducer } from './serverReducer';
import { applicationReducer } from './applicationReducer';
import { applicationServerReducer } from './applicationServerReducer';
import { templateReducer } from './applicationTemplateReducer';

export default combineReducers({
    topic: topicReducer,
    notification: notificationReducer,
    server: serverReducer,
    application : applicationReducer,
    applicationServer : applicationServerReducer,
    template : templateReducer
});