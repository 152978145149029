import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../components/Logo';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { signOut } from '../../services/AuthService';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white'
  },
  avatar: {
    width: 40,
    height: 40
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigation = useNavigate();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="primary">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <SyncAltIcon onClick={() => { navigation('changeApp') }} />
            </Badge>
          </IconButton>
          <IconButton onClick={() => { signOut() }} color="primary">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <MenuIcon

            />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
