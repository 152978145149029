import {
    TOPIC_HTTP_ERROR, TOPIC_POST_ERROR, TOPIC_POST_LOADING,
    TOPIC_POST, TOPIC_HTTP_RESPONSE, TOPIC_HTTP_LOADING, TOPIC_POST_FORM
} from '../constants/types';

const INITIAL_STATE = {
    topicResponse: [],
    topicResponseLoading: false,
    topicResponseError: null,
    topic: {},
    topicLoading: false,
    topicForm : {}
}

const topicReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOPIC_HTTP_RESPONSE:
            return { ...state, topicResponseLoading: false, topicResponseError: false, topicResponse: action.payload }
        case TOPIC_HTTP_LOADING:
            return { ...state, topicResponseLoading: action.payload }
        case TOPIC_HTTP_ERROR:
            return { ...state, topicResponseError: action.payload }
        case TOPIC_POST:
            return { ...state, topic: action.payload, topicLoading: false }
        case TOPIC_POST_LOADING:
            return { ...state, topicLoading: action.payload }
        case TOPIC_POST_FORM:
            return { ...state, topicForm: action.payload }
        default:
            return state;
    }
}

export { topicReducer }