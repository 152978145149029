import {
    NOTIFICATION_HTTP_ERROR, NOTIFICATION_HTTP_LOADING, NOTIFICATION_HTTP_RESPONSE
    , NOTIFICATION_POST, NOTIFICATION_POST_FORM, NOTIFICATION_POST_LOADING
} from '../constants/types';

const INITIAL_STATE = {
    notificationResponse: [],
    notificationResponseLoading: false,
    notificationResponseError: null,
    notification: {},
    notificationLoading: false,
    notificationForm: {},
    notificationMessage: {}
}

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_HTTP_RESPONSE:
            return { ...state, notificationResponseLoading: false, notificationResponseError: false, notificationResponse: action.payload }
        case NOTIFICATION_HTTP_LOADING:
            return { ...state, notificationResponseLoading: action.payload }
        case NOTIFICATION_HTTP_ERROR:
            return { ...state, notificationResponseError: action.payload }
        case NOTIFICATION_POST:
            return { ...state, notification: action.payload, notificationLoading: false }
        case NOTIFICATION_POST_LOADING:
            return { ...state, notificationLoading: action.payload }
        case NOTIFICATION_POST_FORM:
            return { ...state, notificationForm: action.payload }
        case 'NOTIFICATON_MESSAGE':
            return { ...state, notificationMessage: action.payload }
        default:
            return state;
    }
}

export { notificationReducer }