import React, { useEffect } from 'react';
import {
  Box,
  Container,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import Form from './Form';
import Detail from './Detail';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateAction } from '../../actions/applicationTemplateAction';
import { getTopicAction } from '../../actions/topicAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TopicView = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { templateLoading } = useSelector(state => state.template);

  useEffect(() => {
    dispatch(getTopicAction());
    dispatch(getTemplateAction());
  }, [])

  return (
    <Page
      className={classes.root}
      title="Notificaiton"
    >
      <Form />
      <Detail />
      <Container maxWidth={false}>
        <Toolbar
        />
        {
          templateLoading && <CircularProgress />
        }
        <Box mt={3}>
          <Results />
        </Box>
      </Container>
    </Page>
  );
};

export default TopicView;
