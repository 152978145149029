import {
    APPLICATION_HTTP_LOADING, APPLICATION_HTTP_RESPONSE,
    APPLICATION_POST, APPLICATION_POST_ERROR, APPLICATION_POST_FORM, APPLICATION_POST_LOADING,
    APPLICATION_HTTP_ERROR, dispatcher
} from "../constants/types";
import {
    getApplication, createApplication, getAllApplication, updateApplication
} from '../services/ApiService';

const formType = { modalShow: false, clearForm: false }

export const getApplicationAction = (id) => {
    return (dispatch) => {
        if (id) {
            dispatcher(dispatch, APPLICATION_POST_LOADING, true);
            getApplication(id).then(res => {
                dispatcher(dispatch, APPLICATION_POST, res.data);
            }).catch(err => { dispatcher(dispatch, APPLICATION_HTTP_ERROR, err) });
        }
        else {
            dispatcher(dispatch, APPLICATION_HTTP_LOADING, true);
            getAllApplication().then(res => {
                dispatcher(dispatch, APPLICATION_HTTP_RESPONSE, res.data);
            }).catch(err => { dispatcher(dispatch, APPLICATION_HTTP_ERROR, err) });
        }
    };
};

export const applicationFormAction = (form = formType) => {
    return (dispatch) => {
        console.log(form)
        dispatcher(dispatch, APPLICATION_POST_FORM, form);
    };
};

export const insertOrUpdateApplicationAction = (topicInput) => {
    return (dispatch) => {
        dispatcher(dispatch, APPLICATION_POST_LOADING, true);
        if (topicInput.id) {
            updateApplication(topicInput).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, APPLICATION_POST_ERROR, err) })
        }
        else {
            createApplication(topicInput).then(() => { complatePost(dispatch) }).catch(err => { dispatcher(dispatch, APPLICATION_POST_ERROR, err) })
        }
    };
};

const complatePost = (dispatch) => {
    dispatcher(dispatch, APPLICATION_POST_LOADING, false);
    dispatcher(dispatch, APPLICATION_HTTP_LOADING, false);
    dispatcher(dispatch, APPLICATION_POST_FORM, { modalShow: false, clearForm: true });
    getAllApplication().then(res => {
        dispatcher(dispatch, APPLICATION_HTTP_RESPONSE, res.data);
    }).catch(err => { dispatcher(dispatch, APPLICATION_HTTP_ERROR, err) });
}

