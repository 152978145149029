export const DeviceType = {
    "Unkown": 0,
    "Ios": 1,
    "Android": 2,
    "Web": 3
}

export const DeviceTypeList = [
    {
        key: 'Tüm Cihazlar',
        value: null
    },
    {
        key: 'Android',
        value: DeviceType.Android
    },
    {
        key: 'iOS',
        value: DeviceType.Ios
    },
    {
        key: 'Web',
        value: DeviceType.Web
    }
]