import httpService from './HttpService'

export function getAllApplication() {
    return httpService.get('/v1/application');
}

export function sendNotification(notification) {
    return httpService.post('/v1/applicationnotification', notification)
}

export function getNotification() {
    return httpService.get('/v1/applicationnotification', { start: 0, length: 40 })
}

export function createApplication(application) {
    return httpService.post('/v1/application', application, null);
}

export function getApplicationTopic() {
    return httpService.get('/v1/applicationtopic', null);
}

export function getApplicationTopicById(id) {
    return httpService.get('/v1/applicationtopic/' + id, null);
}

export function getApplicationStatistics() {
    return httpService.get('/v1/applicationstatistics', null);
}

export function updateApplicationTopic(payload) {
    return httpService.put('/v1/applicationtopic', payload);
}

export function insertApplicationTopic(payload) {
    return httpService.post('/v1/applicationtopic', payload);
}

export function getApplication(id) {
    return httpService.get('/v1/application/' + id)
}

export function getHealthCheckDevice() {
    return httpService.get('/v1/healthcheckdevice')
}

export function updateApplication(application) {
    return httpService.put('/v1/application', application)
}

export function getNotificationTemplate() {
    return httpService.get('/v1/applicationtemplate', { start: 0, length: 40 });
}

export function getNotificationTemplateById(id) {
    return httpService.get('/v1/applicationtemplate/' + id);
}

export function insertNotificationTemplate(data) {
    return httpService.post('/v1/applicationtemplate', data);
}

export function updateNotificationTemplate(id, data) {
    return httpService.put('/v1/applicationtemplate/' + id, data);
}

export function login(username, password) {
    return httpService.post('/v1/account/login', { username: username, password: password });
}

// user And Account

export function accountCallBack() {
    return httpService.get('/v1/account/callbackhandler', {});
}

export function getUserApplication() {
  return getAllApplication();
}

export function changeApplication(applicationId) {
    return httpService.post('/v1/account/changeapplication', {
        applicationId: applicationId
    });
}

export function getAllServer() {
    return httpService.get('/v1/server');
}

export function getServer(id) {
    return httpService.get('/v1/server/' + id);
}

export function insertServer(serverInput) {
    return httpService.post('/v1/server', serverInput);
}

export function updateServer(serverInput) {
    return httpService.put('/v1/server', serverInput);
}

export function getApplicationServer(applicationId) {
    return httpService.get('/v1/applicationserver/byapplicationid/' + applicationId);
}

export function getApplicationServerById(applicationServerId) {
    return httpService.get('/v1/applicationserver/' + applicationServerId);
}

export function insertApplicationServer(input) {
    return httpService.post('/v1/applicationserver', input);
}

export function updateApplicationServer(input) {
    return httpService.put('/v1/applicationserver', input);
}
