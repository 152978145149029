
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { isAuth } from '../src/services/AuthService';

import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import NotificationView from './views/notification';
import TopicView from './views/topic';
import DashboardView from './views/reports/DashboardView';
import LoginView from './views/auth/LoginView';
import ChangeAppView from './views/auth/ChangeAppView';
import ApplicationView from './views/application';
import NotificationTemplateView from './views/notificationTemplate';

const privateRoutes = [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <DashboardView /> },
        { path: 'applications', element: <ApplicationView /> },
        { path: 'notification', element: <NotificationView /> },
        { path: 'topic', element: <TopicView /> },
        { path: 'changeapp', element: <ChangeAppView /> },
        { path: 'template', element: <NotificationTemplateView /> },
      ]
    }
  ]
  
  const publicRoutes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LoginView /> },
        { path: 'login', element: <LoginView /> },
        { path: '*', element: <LoginView /> }
      ]
    }
  ];

export default function Routes() {
    return useRoutes(isAuth() ? privateRoutes : publicRoutes);
}
