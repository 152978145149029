import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import QrCoder from 'qrcode.react'
import { getToken } from '../../../../services/AuthService'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const QrCode = ({ ios, android, className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [ios, android],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['IOS', 'Android']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'IOS',
      value: ios,
      icon: AppleIcon,
      color: colors.indigo[500]
    },
    {
      title: 'Android',
      value: android,
      icon: AndroidIcon,
      color: colors.red[600]
    }
  ];

  const getQrCode = () => {
    const token = getToken();
    const url = window.location.href;
    const result = { token: token, api: '' };
    result.api = url.startsWith('https://notify.tr') ? 'http://api-notify.tr' : 'https://test-api-notify.tr';
    return JSON.stringify(result);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Sağlık Kontrolü Cihazı Kaydetme" />
      <Divider />
      <CardContent>
        <Box
          height={300}
          justifyContent="center"
          display={'flex'}
        >
          <QrCoder size={350} value={getQrCode()} />
        </Box>
      </CardContent>
    </Card>
  );
};

QrCode.propTypes = {
  className: PropTypes.string
};

export default QrCode;
