import {
    APPLICATION_HTTP_ERROR, APPLICATION_POST_ERROR, APPLICATION_POST_LOADING,
    APPLICATION_POST, APPLICATION_HTTP_RESPONSE, APPLICATION_HTTP_LOADING, APPLICATION_POST_FORM
} from '../constants/types';

const INITIAL_STATE = {
    applicationResponse: [],
    applicationResponseLoading: false,
    applicationResponseError: null,
    application: {},
    applicationLoading: false,
    applicationForm : {}
}

const applicationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case APPLICATION_HTTP_RESPONSE:
            return { ...state, applicationResponseLoading: false, applicationResponseError: false, applicationResponse: action.payload }
        case APPLICATION_HTTP_LOADING:
            return { ...state, applicationResponseLoading: action.payload }
        case APPLICATION_HTTP_ERROR:
            return { ...state, applicationResponseError: action.payload }
        case APPLICATION_POST:
            return { ...state, application: action.payload, applicationLoading: false }
        case APPLICATION_POST_LOADING:
            return { ...state, applicationLoading: action.payload }
        case APPLICATION_POST_FORM:
            return { ...state, applicationForm: action.payload }
        default:
            return state;
    }
}

export { applicationReducer }