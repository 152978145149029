import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux'
import { insertOrUpdateApplicationAction, applicationFormAction } from '../../actions'

export default function FormDialog() {
    const defaultState = {
        name: '',
        providerType: 1,
        fcmCredential: '',
        url: ''
    }
    const [values, setValues] = useState(defaultState);
    const { application, applicationLoading, applicationForm } = useSelector(state => state.application);
    const { modalShow, clearForm } = applicationForm;
    const text = values?.id ? 'Güncelle' : 'Oluştur';
    const dispatch = useDispatch()

    useEffect(() => {
        if (application?.id) {
            setValues(application);   
        }
    }, [application]);

    const handleClose = () => {
        dispatch(applicationFormAction({ modalShow: false }));
        setValues(defaultState);
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeCheck = (event) => {
        setValues({
            ...values,
            autoSubscribe: event.target.checked
        });
    };

    const addOrUpdate = (event) => {
        if (values.name === undefined || values.name === '') {
            alert('Lütfen uygulama adı giriniz')
        }
        else if (values.url === undefined || values.url === '') {
            alert('Lütfen url giriniz')
        }
        else {
            dispatch(insertOrUpdateApplicationAction(values))
        }
    }
    return (
        <Dialog fullWidth open={modalShow && !applicationLoading} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Uygulama {text}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Uygulama adı"
                    type="text"
                    fullWidth
                />

                <TextField
                    disabled={values?.id}
                    margin="dense"
                    name="fcmCredential"
                    value={values.fcmCredential}
                    onChange={handleChange}
                    label="Firebase Json"
                    type="text"
                    fullWidth
                />

                <TextField
                    margin="dense"
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    label="Uygulama Adresleri (örn: localhost:5000, localhost:5001)"
                    type="text"
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    İptal
                </Button>
                <Button onClick={addOrUpdate} color="primary">
                    {text}
                </Button>
            </DialogActions>
        </Dialog>
    );
}